<template>
  <component :is="tag" v-on="$listeners">
    <b-avatar size="42" :src="profileURL + user.avatar" :badge="isChatContact" class="badge-minimal bg-primary" :badge-variant="resolveAvatarBadgeVariant(user.status)" variant="primary" />
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">{{ user.fullName }}</h5>
      <p class="card-text text-truncate">
        {{ isChatContact ? user.chat.lastMessage.message : user.about }}
      </p>
    </div>
    <div v-if="isChatContact" class="chat-meta text-nowrap">
      <small class="float-right mb-25 chat-time">{{ dateFormat(user.chat.lastMessage.time) }}</small>
      <!-- {{ formatDateToMonthShort(user.chat.lastMessage.time, { hour: 'numeric', minute: 'numeric' }) }} -->
      <b-badge v-if="user.chat.unseenMsgs" pill variant="primary" class="float-right"> {{ user.chat.unseenMsgs }} </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue';
import { formatDateToMonthShort } from '@core/utils/filter';
import store from '@/store';
import moment from 'moment';
export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    resolveAvatarBadgeVariant(status) {
      if (status === 'online') return 'success';
      if (status === 'ofline') return 'danger';

      return 'danger';
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format('DD-MM-YYYY HH:mm');
      }
    },
  },
  setup() {
    const profileURL = store.state.app.profileURL;
    return { formatDateToMonthShort, profileURL };
  },
  created() {},
};
</script>

<style></style>
