<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div class="body-content-overlay" />

    <!-- Main Area -->
    <section class="chat-app-window">
      <!-- Start Chat Logo -->
      <!-- Start Chat Logo -->
      <div v-if="!activeChat.contact" class="start-chat-area">
        <div class="mb-1 start-chat-icon">
          <feather-icon icon="MessageSquareIcon" size="56" />
        </div>
        <h4 class="sidebar-toggle start-chat-text" @click="startConversation">Start Conversation</h4>
      </div>

      <!-- Chat Content -->
      <div v-else class="active-chat">
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">
              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon icon="MenuIcon" class="cursor-pointer" size="21" @click="mqShallShowLeftSidebar = true" />
              </div>

              <b-avatar size="36" :src="profileURL + activeChat.contact.avatar" class="mr-1 cursor-pointer badge-minimal" variant="primary" badge :badge-variant="resolveAvatarBadgeVariant()" @click.native="shallShowActiveChatContactSidebar = true" />
              <h6 class="mb-0">{{ activeChat.contact.fullName }}</h6>
            </div>

            <!-- Contact Actions -->
            <div class="d-flex align-items-center">
              <div class="dropdown">
                <b-dropdown variant="link" no-caret toggle-class="p-0" right>
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="17" class="align-middle text-body" />
                  </template>

                  <b-dropdown-item>
                    Clear Chat
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar ref="refChatLogPS" :settings="perfectScrollbarSettings" class="user-chats scroll-area">
          <chat-log :chat-data="activeChat" :profile-user-avatar="profileUserDataMinimal.avatar" />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form class="chat-app-form" @submit.prevent="sendMessage">
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input v-model="chatInputMessage" placeholder="Enter your message" />
          </b-input-group>
          <b-button variant="primary" type="submit">
            Send
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :contacts="contacts"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        :active-chat-contact-id="activeChat.contact ? activeChat.contact.id : null"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        @open-chat="openChatOfContact"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store';
import { ref, onUnmounted, nextTick } from '@vue/composition-api';
import { BAvatar, BDropdown, BDropdownItem, BForm, BInputGroup, BFormInput, BButton } from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig';
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app';
import ChatLeftSidebar from './ChatLeftSidebar.vue';
import chatStoreModule from './chatStoreModule';
import ChatLog from './ChatLog.vue';
import { timestamp } from '@vueuse/shared';
// import soketIo from './soketIo';
export default {
  components: {
    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    // 3rd Party
    VuePerfectScrollbar,
    // SFC
    ChatLeftSidebar,
    ChatLog,
  },

  setup() {
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat';

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME);
    });
    // Active Chat Contact Details
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
  },

  data() {
    const profileURL = store.state.app.profileURL;
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };
    const profileUserData = {};
    const shallShowActiveChatContactSidebar = false;
    var chatUserList = JSON.parse(localStorage.getItem('chatUserList'));

    return {
      refChatLogPS: null,
      chatsContacts: [],
      contacts: [],
      profileUserDataMinimal: {},
      activeChat: {},
      chatInputMessage: '',
      mqShallShowLeftSidebar: false,
      profileURL,
      perfectScrollbarSettings,
      profileUserData,
      shallShowActiveChatContactSidebar,
      onlineUsers: [],
      chatUserList,
    };
  },

  watch: {
    profileUserDataMinimal: function() {
      // const userId = this.profileUserDataMinimal.id;
      // this.$socket.auth = { userId };
      // this.$socket.connect();
    },

    onlineUsers: {
      handler: function(val, before) {
        this.chatsContacts.forEach((contacts) => {
          contacts.status = 'offline';
        });

        this.onlineUsers.forEach((element) => {
          const onlineContact = this.chatsContacts.find((c) => c.id === element.userId);
          if (onlineContact) onlineContact.status = 'online';
        });
      },
    },
  },

  computed: {},

  mounted() {
    // var userData = JSON.parse(localStorage.getItem('userData'));
    // var userId = userData.id;
    // this.$socket.auth = { userId };
    // this.$socket.connect();
    setTimeout(() => {
      this.onlineUsers = this.chatUserList;
      this.chatsContacts.forEach((contacts) => {
        contacts.status = 'offline';
      });
      this.chatUserList.forEach((element) => {
        const onlineContact = this.chatsContacts.find((c) => c.id === element.userId);
        if (onlineContact) onlineContact.status = 'online';
      });
    }, 500);
  },

  created() {
    // this.$socket.emit('LOGOUT_USER');

    this.$socket.on('USERS', (users) => {
      this.chatUserList = users;
    });

    this.fetchChatAndContacts();

    this.$socket.on('USERS', (users) => {
      this.onlineUsers = users;
    });

    // this.$socket.on('USER_CONNECTION', (user) => {
    //   this.onlineUsers.push(user);
    // });

    this.$socket.on('SEND_MESSAGE', (content) => {
      const contact = this.chatsContacts.find((c) => c.id === content.senderUserId);
      if (contact) {
        if (this.activeChat.chat) {
          if (this.activeChat.chat.id !== content.chatId) {
            // Increase unseenMsgs
            contact.chat.unseenMsgs++;
          } else {
            // Add incoming message to active chat

            this.activeChat.chat.chat.push({
              message: content.message,
              time: content.time,
              senderId: content.senderUserId,
            });
          }
        } else {
          // Increase unseenMsgs
          contact.chat.unseenMsgs++;
        }
        contact.chat.lastMessage = {
          message: content.message,
          time: content.time,
          senderId: content.senderUserId,
        };
      }
    });
  },

  methods: {
    resolveAvatarBadgeVariant() {
      return this.onlineUsers.find((user) => user.userId === this.activeChat.contact.id) ? 'success' : 'danger';
    },

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------

    fetchChatAndContacts() {
      store.dispatch('app-chat/fetchChatsAndContacts').then((response) => {
        this.chatsContacts = response.data.chatsContacts;
        this.contacts = response.data.contacts;

        // eslint-disable-next-line no-use-before-define
        this.profileUserDataMinimal = response.data.profileUser;
      });
    },

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------

    openChatOfContact(userId) {
      // Reset send message input value
      this.chatInputMessage = '';
      store.dispatch('app-chat/getChat', { userId }).then((response) => {
        this.activeChat = response.data;
        // Set unseenMsgs to 0
        const contact = this.chatsContacts.find((c) => c.id === userId);
        if (contact) contact.chat.unseenMsgs = 0;

        localStorage.setItem('unseenMsgs', response.data.unseenMsgsCount);

        // Scroll to bottom
        nextTick(() => {
          this.scrollToBottomInChatLog();
        });
      });
      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      this.mqShallShowLeftSidebar = false;
    },

    startConversation() {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        this.mqShallShowLeftSidebar = true;
      }
    },

    scrollToBottomInChatLog() {
      const scrollEl = this.$refs.refChatLogPS.$el || this.refChatLogPS;

      scrollEl.scrollTop = scrollEl.scrollHeight;
    },

    sendMessage() {
      if (!this.chatInputMessage) return;
      const payload = {
        contactId: this.activeChat.contact.id,
        // eslint-disable-next-line no-use-before-define
        senderId: this.profileUserDataMinimal.id,
        message: this.chatInputMessage,
        chatId: this.activeChat.chat.id,
      };
      store.dispatch('app-chat/sendMessage', payload).then((response) => {
        const { newMessageData, chat } = response.data;
        // ? If it's not undefined => New chat is created (Contact is not in list of chats)
        if (chat !== undefined) {
          this.activeChat = { chat, contact: this.activeChat.contact };

          this.chatsContacts.push({
            ...this.activeChat.contact,
            chat: {
              id: chat.id,
              lastMessage: newMessageData,
              unseenMsgs: 0,
            },
          });
        } else {
          // Add message to log
          this.activeChat.chat.chat.push(newMessageData);
        }

        // Set Last Message for active contact
        const contact = this.chatsContacts.find((c) => c.id === this.activeChat.contact.id);
        contact.chat.lastMessage = newMessageData;
        // Scroll to bottom
        nextTick(() => {
          this.scrollToBottomInChatLog();
        });

        // SOCKET.IO
        if (this.onlineUsers.find((user) => user.userId === this.activeChat.contact.id) != undefined) {
          const content = {
            senderSocketId: this.$socket.id,
            receiverSocketId: this.onlineUsers.find((user) => user.userId === this.activeChat.contact.id).socketId,
            chatId: this.activeChat.chat.id,
            senderUserId: this.profileUserDataMinimal.id,
            receiverUserId: this.activeChat.contact.id,
            time: new Date(),
            message: this.chatInputMessage,
          };

          // var userData = JSON.parse(localStorage.getItem('userData'));
          // var userId = userData.id;
          // this.$socket.auth = { userId };
          // this.$socket.connect();

          this.$socket.emit('SEND_MESSAGE', content);
        }
        this.chatInputMessage = '';
        // Reset send message input value
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import '~@core/scss/base/pages/app-chat.scss';
@import '~@core/scss/base/pages/app-chat-list.scss';
</style>
