import axios from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchChatsAndContacts() {
      return new Promise((resolve, reject) => {
        axios
          .get('chatsAndContacts')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    getChat(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`getChat/${userId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    sendMessage(ctx, { contactId, message, senderId, chatId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`sendMessage/${contactId}`, { message, senderId, chatId })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
